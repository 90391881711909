// These declared in userMessages.jsp
// var growlErrorMessages = [];
// var growlWarningMessages = [];
// var growlInfoMessages = [];

(function () {
    addMessages(growlErrorMessages, 'addErrorMessage');
    addMessages(growlWarningMessages, 'addWarningMessage');
    addMessages(growlInfoMessages, 'addInfoMessage');

    function addMessages (list, fn) {
        for (var i = 0; i < list.length; i++) {
            growlMessages[fn](list[i]);
        }
    }
}());
